exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-calculation-de-jsx": () => import("./../../../src/pages/calculation-de.jsx" /* webpackChunkName: "component---src-pages-calculation-de-jsx" */),
  "component---src-pages-calculation-en-jsx": () => import("./../../../src/pages/calculation-en.jsx" /* webpackChunkName: "component---src-pages-calculation-en-jsx" */),
  "component---src-pages-downloads-de-jsx": () => import("./../../../src/pages/downloads-de.jsx" /* webpackChunkName: "component---src-pages-downloads-de-jsx" */),
  "component---src-pages-downloads-en-jsx": () => import("./../../../src/pages/downloads-en.jsx" /* webpackChunkName: "component---src-pages-downloads-en-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-quote-de-jsx": () => import("./../../../src/pages/quote-de.jsx" /* webpackChunkName: "component---src-pages-quote-de-jsx" */),
  "component---src-pages-quote-en-jsx": () => import("./../../../src/pages/quote-en.jsx" /* webpackChunkName: "component---src-pages-quote-en-jsx" */)
}


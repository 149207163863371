import { createTheme, responsiveFontSizes } from "@mui/material";

let theme = createTheme({
  palette: {
    primary: {
      main: '#E30613',
      light: '#ff563f',
      dark: '#a80000'
    },
    secondary: {
      main: '#00778B',
      light: '#599ef3',
      dark: '#00468f'
    },
    background: {
      default: '#ffffff',
    },
    white: {
      main: '#ffffff'
    },
    hvgray: {
      main: '#7f7f7f',
      dark: '#404040',
      light: '#cacbcc'
    }
  },
  typography: {
    fontSize: 12,
    fontFamily: [
        'Lato',
        'Helvetica',
        'Arial',
        'sans-serif'
      ].join(','),
    h1: {
      fontFamily: 'Oswald',
      fontWeight: 500
    },
    h2: {
      fontFamily: 'Oswald',
      fontWeight: 500
    },
    h3: {
      fontFamily: 'Oswald',
      fontWeight: 500
    },
    h4: {
      fontFamily: 'Oswald',
      fontWeight: 500
    },
    h5: {
      fontFamily: 'Oswald',
      fontWeight: 500
    },
    h6: {
      fontFamily: 'Oswald',
      fontWeight: 500
    },
    caption: {
      fontSize: '0.8rem'
    },
    body1: {
      fontSize: '1rem'
    },
    body2: {
      fontSize: '0.8rem'
    }
  }
});

theme = responsiveFontSizes(theme);

export default theme;